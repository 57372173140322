import {EarnedRecognition} from "./graphs/er";
import {DrivercheckCore} from "./graphs/drivercheck_core";
import {TableCore} from "./graphs/tables/table_core";
import moment from 'moment';
import {Chart} from 'chart.js/auto';
import {AGLegendPlugin} from "./graphs/chartjs-plugins/legend_plugin";
window.Chart = Chart;
import {AjaxPromise, AjaxSync} from "./shared/ajax_utilities";
import {dateConvert} from "./shared/text_utilities";
import {manifest} from "./shared/common";

export class DashboardCore {
    DASHBOARD = app.OPTIONS.dashboard;
    messageClose = false;
    GRID;
    TABLE_DATA = {};
    GRAPH_DATA = {};
    OVERVIEW_DATA = {};
    GRAPH_CLASSES = {};
    TPL_CLASSES = {};
    TABLE_CLASSES = {};
    TPL_LOAD_HANDLER = {};
    GRAPH_RELOADING = {};
    COUNTDOWN_INTERVAL;

    constructor() {
        app.DASHBOARD_CORE = this;
        app.GRAPH_CLASSES = {};
        app.DOM.filter = app.DOM.content.find('#filter');

        if(app.OPTIONS.gridstack) {
            console.time('Setup Gridstack');
            this.setupGridstack();
            console.timeEnd('Setup Gridstack');
        }

        // dashboard filters
        this.setupFiltersHome();

        if(app.OPTIONS.er) {
            app.EARNED_RECOGNITION.setupEarnedRecognition();
        }

        if( app.DOM.earned && app.DOM.earned.length > 0) {
            app.EARNED_RECOGNITION.setupTooltipsER();
        }

        this.setupTabs();

        if(app.OPTIONS.drivercheck && app.DRIVERCHECK_CORE) {
            app.DRIVERCHECK_CORE.setupTooltipsDrivercheck();
        }

        if (app.OPTIONS.tacho) {
            app.DOM.content.find('.tipso').tipso({
                useTitle: true,
                background:'#559F48'
            });
        }

        if(this.DASHBOARD === 'home') {
            this.setupMessageHome();
        }

        this.setupKeys();

        if(app.OPTIONS.multiSelect) {
            app.multiSelect(false, true);
        }

        if(app.OPTIONS.dashboard_ajax && app.OPTIONS.gridstack) {
            (async () => {
                try {
                    new TableCore();
                    const promises = [this.loadInitialOverview()];
                    if(this.DASHBOARD !== 'tacho') {
                        let hasTables = false;
                        let hasGraphs = false;
                        Object.keys(app.OPTIONS.gridstack_allowed).forEach((gridId) => {
                            const opts = app.OPTIONS.gridstack_allowed[gridId];
                            if(!opts.enabled) {
                                return;
                            }

                            this.setLoadingState(gridId, true);
                            if(opts.renderType === 'table') {
                                hasTables = true;
                            }

                            if(opts.renderType === 'graph') {
                                hasGraphs = true;
                            }

                            if(opts.renderType === 'tpl') {
                                promises.push(this.loadInitialTpl(opts, gridId));
                            }
                        });

                        if(hasGraphs) {
                            promises.push(this.loadInitialGraphs());
                        }

                        if(hasTables) {
                            promises.push(this.loadInitialTables());
                        }
                    }

                    if(this.DASHBOARD === 'drivercheck') {
                        promises.push(app.DRIVERCHECK_CORE.getLicenseChecks());
                    }

                    this.startCountdownInterval();
                    await Promise.all(promises);
                    this.populateAdditionalRows();
                    app.AJAX = false;
                    if (this.DASHBOARD === 'tacho') {
                        this.setupUserVehicleHover();
                    }

                    app.TABLE_CORE.init();
                } catch(err) {
                    console.warn('Failed to load initial graph data:', err);
                }
            })();
        }
    }

    setLoadingState(gridId, loading) {
        this.setupCountdowns();
        const container = app.DOM.content.find(`#${gridId}`);
        if(!container.hasClass('widget-loading')) {
            container.removeClass('loading-content');
            container.find('.wrapper').show()
            return;
        }

        const contentWrapper = container.find('.grid-stack-item-content-inner');
        if(loading) {
            contentWrapper.find('.loading').remove();
            contentWrapper.find('.wrapper').hide();
            contentWrapper.prepend(`<img class='loading' src='${manifest('img/loading-black.svg')}' width='30'>`);
            contentWrapper.addClass('loading-content');
            return;
        }

        contentWrapper.find('.wrapper').show();
        contentWrapper.removeClass('loading-content');
    }

    async loadInitialTpl(opts, gridId) {
        if(opts.tpl_js !== false || this.TPL_CLASSES[gridId]) {
            if(!this.TPL_CLASSES[gridId] || !this.TPL_CLASSES[gridId].setup) {
                this.TPL_LOAD_HANDLER[gridId] = setInterval(() => {
                    if(this.TPL_CLASSES[gridId] && this.TPL_CLASSES[gridId].setup) {
                        try {
                            this.TPL_CLASSES[gridId].setup();
                            clearInterval(this.TPL_LOAD_HANDLER[gridId]);
                            app.TABLE_CORE.init();
                            this.setLoadingState(gridId, false);
                            this.populateAdditionalRows();
                        } catch(err) { clearInterval(this.TPL_LOAD_HANDLER[gridId]); }
                    }
                }, 100);
                return;
            }

            await this.TPL_CLASSES[gridId].setup();
            this.setLoadingState(gridId, false);
            this.populateAdditionalRows();
            app.TABLE_CORE.init();
            return;
        }

        try {
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX_TPL}${opts.ajax_tpl}`,
                method: 'POST',
                data: {
                    dashboard: app.DASHBOARD_CORE.DASHBOARD,
                    allowed: app.DASHBOARD_CORE.getDataGridstack(),
                }
            });
            this.paintTpl(opts.ajax_tpl, res, gridId);
            this.populateAdditionalRows();
            this.setLoadingState(gridId, false);
            app.TABLE_CORE.init();
        } catch(err) {
            console.warn('Error Loading tpl content.', err);
        }
    }

    async loadInitialOverview() {
        try {
            console.time('Overview Initial Load');
            this.OVERVIEW_DATA = await this.getOverviewData();
            if(this.DASHBOARD === 'tacho') {
                // TODO: we need to in future allow fetching graphs/tables from other ajax endpoints for tacho
                const [tableData, tachoGraphData] = app.DASHBOARD_CORE.partitionData(this.OVERVIEW_DATA);
                this.TABLE_DATA = Object.assign(this.TABLE_DATA, tableData);
                this.GRAPH_DATA = Object.assign(this.GRAPH_DATA, tachoGraphData);
                await this.initGraphs({ data: tachoGraphData });
                await this.initTables({ data: tableData });
            }

            this.paintOverview();
            console.timeEnd('Overview Initial Load');
        } catch(err) {
            console.warn('Error Loading overview graphs.', err);
        }
    }

    async loadInitialGraphs() {
        try {
            console.time('Graphs Initial Load');
            const res = await this.getGraphData();
            if(res.status !== 'success') {
                return;
            }

            this.GRAPH_DATA = Object.assign(this.GRAPH_DATA, res.data);
            await this.initGraphs(res);
            console.timeEnd('Graphs Initial Load');
        } catch(err) {
            console.warn('Error Loading initial graphs.', err);
        }
    }

    async initGraphs(res) {
        for(const gridId of Object.keys(app.OPTIONS.gridstack_allowed)) {
            const opts = app.OPTIONS.gridstack_allowed[gridId];
            if(!opts.enabled) {
                continue;
            }

            if(res && !res.data[gridId]) {
                continue;
            }

            if(opts.renderType === 'graph') {
                const el = app.DOM.content.find(`#${gridId}`);
                if(this.GRAPH_DATA[gridId] && this.GRAPH_DATA[gridId].refresh_interval) {
                    app.DOM.content.find(`#${gridId} .gridstack-timer`).remove();
                    el.prepend(`<span class="gridstack-timer" gs-id="${gridId}"></span>`);
                    app.DOM.content.find(`#${gridId} .gridstack-timer`).attr('timer', this.getCountdownTime(this.GRAPH_DATA[gridId].refresh_interval));
                }

                this.setLoadingState(gridId, true);
                if(this.GRAPH_CLASSES[gridId] && this.GRAPH_CLASSES[gridId].setup) {
                    await this.GRAPH_CLASSES[gridId].setup();
                }
            }
        }
    }

    async loadInitialTables() {
        try {
            console.time('Tables Initial Load');
            const res = await this.getTableData();
            if(res.status !== 'success') {
                return;
            }

            this.TABLE_DATA = Object.assign(this.TABLE_DATA, res.data);
            await this.initTables(res);
            app.TABLE_CORE.init();
            console.timeEnd('Tables Initial Load');
        } catch(err) {
            console.warn('Error Loading initial tables.', err);
        }
    }

    async initTables(res) {
        const tableObj = {}
        for(const gridId of Object.keys(app.OPTIONS.gridstack_allowed)) {
            const opts = app.OPTIONS.gridstack_allowed[gridId];
            if(!opts.enabled) {
                continue;
            }

            if(res && !res.data[gridId]) {
                continue;
            }

            if(opts.renderType === 'table') {
                if(this.TABLE_DATA[gridId] && this.TABLE_DATA[gridId].refresh_interval) {
                    const el = app.DOM.content.find(`#${gridId}`);
                    app.DOM.content.find(`#${gridId} .gridstack-timer`).remove();
                    el.prepend(`<span class="gridstack-timer" gs-id="${gridId}"></span>`);
                    app.DOM.content.find(`#${gridId} .gridstack-timer`).attr('timer', this.getCountdownTime(this.TABLE_DATA[gridId].refresh_interval));
                }

                if(this.TABLE_CLASSES[gridId] && this.TABLE_CLASSES[gridId].setup) {
                    await this.TABLE_CLASSES[gridId].setup();
                }

                this.setLoadingState(gridId, false);

                if (opts.topup_rows) {
                    tableObj[gridId] = app.DOM.content.find(`#${gridId} table tbody`);
                }
            }
        }

        this.topupRows(5, tableObj);
    }

    setupCountdowns() {
        const countdowns = app.DOM.content.find('.gridstack-timer');
        const graphsWithCountdowns = [];
        $.each(countdowns, (k, el) => {
            el = $(el);
            const currentDisplay = el.css('display');
            if(!el.attr('timer')) {
                el.hide();
                return;
            }

            el.show();
            const gridId = el.attr('gs-id');
            if(gridId && this.OVERVIEW_DATA[gridId]) {
                if(this.OVERVIEW_DATA[gridId] && this.OVERVIEW_DATA[gridId].refresh_interval) {
                    el.attr('timer', this.getCountdownTime(this.OVERVIEW_DATA[gridId].refresh_interval));
                }
            } else if(this.TABLE_DATA[gridId] && this.TABLE_DATA[gridId].refresh_interval) {
                el.attr('timer', this.getCountdownTime(this.TABLE_DATA[gridId].refresh_interval));
            } else if(this.GRAPH_DATA[gridId] && this.GRAPH_DATA[gridId].refresh_interval) {
                el.attr('timer', this.getCountdownTime(this.GRAPH_DATA[gridId].refresh_interval));
            }

            const timer = parseInt(el.attr('timer'));
            if(timer === -1 || isNaN(timer)) {
                return;
            }
            el.attr('current-time', (timer) * 1000);
            el.text(`${this.msToTime((timer * 1000))}`);
            if(this.GRAPH_CLASSES[el.attr('gs-id')] && !$(el.parent()).is('h3') && currentDisplay === 'none') {
                graphsWithCountdowns.push(el.attr('gs-id'));
            }
        });

        graphsWithCountdowns.forEach((gridId) => {
            const grid = $(`#${gridId}`);
            Object.keys(Chart.instances).forEach((key) => {
                const chart = Chart.instances[key];
                if(!grid.find('canvas').is($(chart.canvas))) {
                    return;
                }

                chart.height -= 22;
                chart.update();
            });
        });
    }

    startCountdownInterval() {
        setInterval(async () => {
            let graphsToFetch = [];
            let tablesToFetch = [];
            const countdowns = app.DOM.content.find('.gridstack-timer');
            $.each(countdowns, (k, el) => {
                el = $(el);
                if(!el.attr('timer')) {
                    return;
                }

                let currentTime = parseInt(el.attr('current-time'));
                if(currentTime - 1000 <= 0) {
                    const allowed = app.OPTIONS.gridstack_allowed[el.attr('gs-id')];
                    if(!allowed || !allowed.enabled) {
                        return;
                    }

                    if(allowed.renderType === 'graph') {
                        graphsToFetch.push(el.attr('gs-id'));
                    } else if(allowed.renderType === 'table') {
                        tablesToFetch.push(el.attr('gs-id'));
                    }
                }
            });

            let res;
            if(graphsToFetch.length > 0) {
                res = await this.getGraphData(graphsToFetch);
                if(res.status === 'success') {
                    this.GRAPH_DATA = Object.assign(this.GRAPH_DATA, res.data);
                }
            }

            if(tablesToFetch.length > 0) {
                res = await this.getTableData(tablesToFetch);
                if(res.status === 'success') {
                    this.TABLE_DATA = Object.assign(this.TABLE_DATA, res.data);
                }
            }

            $.each(countdowns, async (k, el) => {
                el = $(el);
                if(!el.attr('timer')) {
                    el.hide();
                    return;
                }
                const gridId = el.attr('gs-id');
                if(this.GRAPH_RELOADING[gridId]) {
                    return;
                }

                el.show();
                let currentTime = parseInt(el.attr('current-time'));
                if(currentTime - 1000 <= 0) {
                    currentTime = 0;

                    if(gridId && this.OVERVIEW_DATA[gridId]) {
                        this.OVERVIEW_DATA = await this.getOverviewData();
                        this.paintOverview();
                        if(this.OVERVIEW_DATA[gridId] && this.OVERVIEW_DATA[gridId].refresh_interval) {
                            el.attr('timer', this.getCountdownTime(this.OVERVIEW_DATA[gridId].refresh_interval));
                        }
                    } else if(this.TABLE_CLASSES[gridId] && this.TABLE_CLASSES[gridId].update) {
                        await this.TABLE_CLASSES[gridId].update();
                        if(this.TABLE_DATA[gridId] && this.TABLE_DATA[gridId].refresh_interval) {
                            el.attr('timer', this.getCountdownTime(this.TABLE_DATA[gridId].refresh_interval));
                        }
                    } else if(gridId && this.GRAPH_CLASSES[gridId] && this.GRAPH_CLASSES[gridId].update) {
                        await this.GRAPH_CLASSES[gridId].update();
                        if(this.GRAPH_DATA[gridId] && this.GRAPH_DATA[gridId].refresh_interval) {
                            el.attr('timer', this.getCountdownTime(this.GRAPH_DATA[gridId].refresh_interval));
                        }
                    }

                    this.populateAdditionalRows();
                    currentTime = (1000 * parseInt(el.attr('timer'))) + 500;
                } else {
                    currentTime -= 1000;
                }

                el.attr('current-time', currentTime);
                el.text(`${this.msToTime(currentTime)}`);
            });

        }, 1000);
    }

    getGridstackAllowedOpts(gridIds, type = 'graph') {
        let data = { allowed: {} };
        if(gridIds) {
            $.each(gridIds, function(index, gridId){
                const allowed = app.OPTIONS.gridstack_allowed[gridId];
                const extra = app.OPTIONS.gridstack[gridId];
                if(!extra || !allowed || allowed.renderType !== type) return;
                data.allowed[gridId] = {
                    checked: 1,
                };

                if(extra.opts) {
                    data.allowed[gridId].opts = extra.opts;
                }
            });
        } else {
            Object.keys(app.OPTIONS.gridstack).forEach((gridId) => {
                const allowed = app.OPTIONS.gridstack_allowed[gridId];
                const extra = app.OPTIONS.gridstack[gridId];
                if(!extra || !allowed || allowed.renderType !== type) return;
                data.allowed[gridId] = {
                    checked: 1,
                };

                if(app.OPTIONS.gridstack[gridId].opts) {
                    data.allowed[gridId].opts = extra.opts;
                }
            });
        }

        return data;
    }

    async getGraphData(graphIds = undefined, refresh = false, additionalOpts = undefined) {
        let data = {
            dashboard: this.DASHBOARD,
            allowed: {}
        };

        if(additionalOpts && Object.keys(additionalOpts).length > 0) {
            data = Object.assign(data, additionalOpts);
        }

        if(typeof graphIds === "string") {
            graphIds = [graphIds];
        }

        data = Object.assign(data, this.getGridstackAllowedOpts(graphIds));

        if(refresh) {
            data.refresh = '1';
        }

        return await AjaxPromise({
            url: `${app.CACHE.URL_AJAX_DASHBOARD}graphs`,
            data: data,
            method: 'POST',
        });
    }

    async getTableData(tableIds, refresh, additionalOpts) {
        let data = {
            dashboard: this.DASHBOARD,
            allowed: {}
        };

        if(additionalOpts && Object.keys(additionalOpts).length > 0) {
            data = Object.assign(data, additionalOpts);
        }

        if(typeof tableIds === "string") {
            tableIds = [tableIds];
        }

        data = Object.assign(data, this.getGridstackAllowedOpts(tableIds, 'table'));
        if(refresh) {
            data.refresh = '1';
        }

        return await AjaxPromise({
            url: `${app.CACHE.URL_AJAX_DASHBOARD}tables`,
            data: data,
            method: 'POST',
        });
    }

    async getOverviewData() {
        if(this.DASHBOARD === 'tacho') {
            try {
                app.AJAX = true;
                const res = await this.getTachoOverview();
                app.AJAX = false;
                return res;
            } catch(err) {
                app.AJAX = false;
                console.error(`Ajax error when fetching overview data.`, err);
                throw err;
            }
        }

        if(this.DASHBOARD === 'home' && app.CACHE.USER.user_type_id === '1') {
            return {};
        }

        const res = await AjaxPromise({
            url: `${app.CACHE.URL_AJAX_DASHBOARD}overview`,
            data: {
                dashboard: this.DASHBOARD,
                allowed: this.getDataGridstack()
            },
            method: 'POST'
        });

        if(res.status !== 'success') {
            throw new Error(res.message);
        }

        return res.data;
    }

    paintOverview() {
        if(this.DASHBOARD === 'tacho') {
            // Tacho Dashboard handles its own snapshots through a custom table class
            return;
        }

        const key = '#snapshot_overview';

        // snapshots
        const $parent = app.DOM.gridstack.filter(`${key}`);
        Object.keys(this.OVERVIEW_DATA).forEach((tableKey) => {
            this.setLoadingState(tableKey, false);
            if(tableKey === 'snapshot') {
                const $tds = $parent.find('.ajax');
                $.each(this.OVERVIEW_DATA[tableKey], (k, v) => {
                    const $el = $tds.filter(`${key}_${k}`);
                    if(app.OPTIONS.countUp && $.isNumeric(v)) {
                        $el.countup({
                            endVal: v,
                            duration: 1.5
                        });
                    } else {
                        $el.text(v);
                    }
                });
                return;
            }

            if(this.OVERVIEW_DATA[tableKey].refresh_interval) {
                const el = app.DOM.content.find(`#${tableKey}`);
                app.DOM.content.find(`#${tableKey} .gridstack-timer`).remove();
                el.prepend(`<span class="gridstack-timer" gs-id="${tableKey}"></span>`);
                app.DOM.content.find(`#${tableKey} .gridstack-timer`).attr('timer', this.getCountdownTime(this.OVERVIEW_DATA[tableKey].refresh_interval));
            }

            if(this.OVERVIEW_DATA[tableKey]) {
                let html = '';
                $.each(this.OVERVIEW_DATA[tableKey], (k,v) => {
                    html += '<tr>';
                    $.each(v, (kk,vv) => {
                        html += `<td>${vv}</td>`;
                    });
                    html += '</tr>';
                });
                app.DOM.gridstack.filter(`#${tableKey}`).find('tbody').html(html);
            }
        });
    }

    async updateLayout(layout, action = 'save', settings = undefined) {
        const params = {
            'dashboard': this.DASHBOARD,
            'action': action
        };

        if(layout) {
            params.layout = layout;
        }

        if(settings) {
            params.settings = settings;
        }

        return await AjaxPromise({
            url: `${app.CACHE.URL_AJAX_DASHBOARD}layout`,
            data: params,
            method: 'POST'
        });
    }

    paintTpl(url, r, id) {
        // update dom with content
        const $el = app.DOM.gridstack.filter('#'+id).find('.grid-stack-item-content-inner');

        // apply events to new dom elements
        if(url === 'new_features') {
            this.deleteNewFeatures($el, r);
        } else {
            $el.find('tbody').html(r);
        }
    }

    renderPiechart($canvas, data) {
        var ctx = $canvas[0].getContext('2d'),
            options = {
                maintainAspectRatio: false,
                responsive: false,
                plugins: {
                    title: {
                        display: true,
                        text: data.title
                    }
                }
            };

        new Chart(ctx, {
            'type': data.type,
            'data': data,
            'options': options
        });
    }

    setupGraph(name, data) {
        this.setLoadingState(name, false);
        if(this.GRAPH_CLASSES[name] && this.GRAPH_CLASSES[name].setupGraph) {
            this.GRAPH_CLASSES[name].setupGraph(data);
            return;
        }

        let $container = app.DOM.gridstack.filter(`#${name}`),
            $canvas = $container.find('canvas'),
            $parent = $canvas.closest('.grid-stack-item');

        // check canvas exists
        if( $canvas.length === 0 ) {
            console.warn('Cant find canvas', name);
            return;
        }

        // setup container & canvas
        let ctx = $canvas[0].getContext('2d'),
            options = {
                maintainAspectRatio: false,
                responsive: true
            };

        // chart specific options (checkpro|reseller)
        if( typeof(app.OPTIONS.chartjs) === 'number') {
            options = this.graphOptions3(options, name, data);
        } else {
            options = this.graphOptions2(options, name, data);
        }

        if(this.GRAPH_CLASSES[name].getChartJsOptions) {
            options = Object.assign(options, this.GRAPH_CLASSES[name].getChartJsOptions(options, data) ?? {});
        }

        // title
        if( data.title ) {
            $parent.find('h3').attr('title', data.title).tipso({
                background: app.COLOUR,
                useTitle: true,
                tooltipHover: true,
                position: 'top',
                width: '28%'
            });
        }

        let plugins = [];

        if(this.GRAPH_CLASSES[name].getChartJsPlugins) {
            plugins = this.GRAPH_CLASSES[name].getChartJsPlugins(options, data) ?? [];
        }

        if($container.hasClass('ag_legend')) {
            options = Object.assign(options, {
                plugins: {
                    ag_legend: { graphId: name, containerID: `${name}-legend-container` },
                    legend: { display: false }
                }
            });
            plugins.push(AGLegendPlugin);
        }

        // setup chart
        new Chart(ctx, {
            type: data.type,
            data: data,
            options: {
                maintainAspectRatio: false,
                ...options
            },
            plugins: plugins,
        });

        // update totals
        if(data.totals) {
            $parent.find('.total em').each((i, el) => {
                const k = $(el).attr('data');
                $(el).html(data.totals[k]);
            });
        }

        // change button selection
        if( data.set ) {
            $parent.find('h3 a[data="'+data.set+'"]').addClass('button-primary');
        }

        if(app.OPTIONS.gridstack[name] && app.OPTIONS.gridstack[name].opts && app.OPTIONS.gridstack[name].opts.length === 0) {
            const defaultChecked = app.OPTIONS.gridstack_allowed[name].opts;
            defaultChecked.forEach((toCheck) => {
                $parent.find(`input[value="${toCheck}"]`).prop('checked', 'true');
            });
        } else if(app.OPTIONS.gridstack[name] && app.OPTIONS.gridstack[name].opts && app.OPTIONS.gridstack[name].opts > 0) {
            app.OPTIONS.gridstack[name].opts.forEach((toCheck) => {
                $parent.find(`input[value="${toCheck}"]`).removeProp('checked');
            });
        }

        if(this.GRAPH_CLASSES[name] && this.GRAPH_CLASSES[name].postSetup) {
            this.GRAPH_CLASSES[name].postSetup($parent, data);
        }

        if(this.GRAPH_DATA[name].refresh_interval) {
            $(`#${name} .gridtsack-counter`).attr('gs-id', this.GRAPH_DATA[name].refresh_interval);
        }
    }

    async rebuildGraph(graphId, additionalOpts = undefined, eventTarget = undefined) {
        let $container = app.DOM.gridstack.filter(`#${graphId}`);
        if($container && $container.length > 0) {
            this.setLoadingState(graphId, true);
        }
        try {
            const res = await this.getGraphData(graphId, true, additionalOpts);
            if(res.status !== 'success') {
                if(eventTarget && eventTarget.attr('href') && eventTarget.attr('href').length > 0) {
                    window.location = eventTarget.attr('href');
                    return;
                }

                window.location.reload();
                return;
            }

            const $layoutGrid = app.DOM.gridstack.filter(`#${graphId}`);
            const gridId = $layoutGrid.attr('gs-id');
            if(!app.OPTIONS.gridstack_allowed[gridId] || !app.OPTIONS.gridstack_allowed[gridId].enabled) {
                return;
            }

            Object.keys(res.data).forEach((graphName) => {
                this.GRAPH_DATA[graphName] = res.data[graphName];
            });

            if(!this.GRAPH_DATA[graphId]) {
                return;
            }

            Object.keys(Chart.instances).forEach((key) => {
                const chart = Chart.instances[key];
                if($layoutGrid.find('canvas').is($(chart.canvas))) {
                    chart.destroy();
                    this.setupGraph(graphId, this.GRAPH_DATA[graphId]);
                }
            });
        } catch(err) {
            if(eventTarget && eventTarget.attr('href') && eventTarget.attr('href').length > 0) {
                window.location = eventTarget.attr('href');
                return;
            }
            window.location.reload();
        }
    }

    graphOptions2(options, name, data) {
        if( name === 'res_finance' || name === 'opr_finance' ){
            options.scales.xAxes[0].display = false;
        } else if( options.scales ){
            delete options.scales.xAxes[0].display;
        }

        // click
        if( data.hasOwnProperty('click') === false) {
            return options;
        }

        options.onClick = (e, arr) => {
            if(!e.chart) {
                return;
            }

            const bar = e.chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)[0];
            if(!bar) {
                return;
            }

            const index = bar.index,
                datasetIndex = bar.datasetIndex;

            this.clickChart(datasetIndex, index, data);
        };

        return options;
    }

    graphOptions3(options, name, data) {
        // pass options through php rather than manually set them up
        if( data.options ) {
            console.warn(name, 'manually setting up graph options');
            options = $.extend({}, options, data.options);
        }

        // click event
        if(data.hasOwnProperty('click')) {
            options.onClick = (e, arr) => {
                if(!arr || arr.length === 0) {
                    return;
                }

                this.clickChart(arr[0].datasetIndex, arr[0].index, data);
            };
        }

        return options;
    }

    setupGridstack() {
        if( $('html').hasClass('ie') ) {
            return;
        }
    
        const gridstackControls = '<div class="gridstack-controls"><i class="fa fa-cog modal-settings"></i><i class="fa fa-times"></i></div>';
    
        app.DOM.gridstack = app.DOM.content.find('.grid-stack-item');
        app.DOM.gridstack_tabs = app.DOM.gridstack.filter('.tabs');
        app.DOM.gridstack_graphs = app.DOM.gridstack.filter('.graphs').addClass('widget-loading').find('canvas').before(`<img class='loading' src='${manifest('img/loading-black.svg')}' width='30'>`);

        // add controls to gridstack
        if( app.OPTIONS.customise ) {
            // gridstack elements
            app.DOM.gridstack.find('h3 .buttons').after(gridstackControls);
            app.DOM.gridstack.filter('#snapshot_overview').prepend(gridstackControls);
            app.DOM.gridstack_controls = app.DOM.gridstack.find('.gridstack-controls i').on('click', (e) => this.gridstackControl(e));
            // modal element
            app.DOM.modal_settings = $('#modal-settings');
            app.DOM.modal_settings_input = app.DOM.modal_settings.find('input.allowed');
            app.DOM.modal_settings_input_opts = app.DOM.modal_settings.find('input.allowed_opts');
    
            // setup micromodal
            this.gridstackMicroModal();
        }
    
        // make h3 draggable handle
        app.OPTIONS.gridstack_opts.handle = '.grid-stack-item-content h3';

        // start grid
        this.GRID = GridStack.init(app.OPTIONS.gridstack_opts);
    
        // do nothing further when changing/reset
        if( app.OPTIONS.gridstack_opts.disableSave ) {
            return;
        }

        this.GRID.on('resizestop', (e, gridNodes) => {
            this.populateAdditionalRows();
            Object.keys(Chart.instances).forEach((key) => {
                const chart = Chart.instances[key];
                chart.render();
            });
        });

        this.GRID.on('change', async (e, gridNodes) => {
            const $el = $(e.target || e.srcElement || e.detail[0].id);

            // res
            if($el.hasClass('not-layout-change')) {
                return;
            }

            // dont re-save grid when your just closing message
            if(this.messageClose) {
                // change message close state
                this.messageClose = false;
                return;
            }

            // already processing request
            if(app.AJAX) {
                return;
            }

            // change ajax state
            app.AJAX = true;

            // get array of grid data
            let layout = this.GRID.save();
            const gridstackId = $el.attr('data-gridstack-id');
            let $container = app.DOM.gridstack.filter(`#${gridstackId}`),
                $canvas = $container.find('canvas'),
                $parent = $canvas.closest('.grid-stack-item');

            // If an input has data-gridstack-id we will attempt to apply it as an option to the grid layout
            if(gridstackId && gridstackId.length > 0) {
                layout = layout.map((grid) => {
                    if(app.OPTIONS.gridstack[grid.id].opts && app.OPTIONS.gridstack[grid.id].opts.length === 0) {
                        grid.opts = app.OPTIONS.gridstack_allowed[grid.id].opts;
                    } else {
                        grid.opts = app.OPTIONS.gridstack[grid.id].opts;
                    }

                    if(grid.id === gridstackId) {
                        let hasValue = false;

                        grid.opts.forEach((opt) => {
                            if(opt === $el.val()) {
                                hasValue = true;
                            }
                        });

                        if(hasValue) {
                            grid.opts = grid.opts.filter((opt) => opt !== $el.val());
                        } else {
                            grid.opts.push($el.val());
                        }
                    }

                    if(grid.opts && grid.opts.length === 0) {
                        grid.opts = app.OPTIONS.gridstack_allowed[grid.id].opts;
                    }

                    app.OPTIONS.gridstack[grid.id].opts = grid.opts;
                    if(grid.opts && grid.opts.length > 0) {
                        grid.opts.forEach((toCheck) => {
                            $parent.find(`input[value="${toCheck}"]`).prop('checked', 'true');
                        });
                    }

                    const selectedButton = app.DOM.gridstack_tabs.filter((k, el) => $(el).attr('id') === grid.id)
                        .find(`.buttons .button-primary`);
                    if(selectedButton) {
                        grid.tab = selectedButton.attr('data');
                    }

                    return grid;
                });
            } else {
                layout = layout.map((grid) => {
                    $.each(app.DOM.gridstack_tabs, (k, el) => {
                        if($(el).attr('gs-id') !== grid.id) {
                            return;
                        }

                        const selectedButton = app.DOM.gridstack_tabs.filter((k, el) => $(el).attr('id') === grid.id)
                            .find(`.buttons .button-primary:not(.not-tab)`);
                        if(selectedButton) {
                            grid.tab = selectedButton.attr('data');
                        }
                    });

                    delete grid.content;
                    return grid;
                });
            }

            const inputs = app.DOM.content.find('.grid-stack').find('input, select, a, button');
            inputs.attr('disabled', 'true').addClass('disabled');
            $parent.addClass('widget-loading');
            this.setLoadingState()
            $parent.find('.gridstack-timer').hide();
            this.setLoadingState(gridstackId, true);
            let isGraph = false;
            try {
                if(e.detail && e.detail.length > 0) {
                    if(app.OPTIONS.gridstack_allowed[e.detail[0].id] && app.OPTIONS.gridstack_allowed[e.detail[0].id].renderType === 'graph') {
                        isGraph = true;
                    }
                }

                if(gridstackId && app.OPTIONS.gridstack_allowed[gridstackId] && app.OPTIONS.gridstack_allowed[gridstackId].renderType === 'graph') {
                    isGraph = true;
                }

                if(isGraph) {
                    $parent.find('.gridstack-timer').hide();
                    this.GRAPH_RELOADING[gridstackId] = true;
                }

                layout.map((widget) => {
                    if(app.OPTIONS.gridstack[widget.id]) {
                        const data = app.OPTIONS.gridstack[widget.id];
                        if(data.opts) {
                            widget.opts = data.opts;
                        }

                        if(data.opts_settings) {
                            widget.opts_settings = data.opts_settings;
                        }
                    }

                    return widget
                });

                const res = await this.updateLayout(layout);
                if(app.OPTIONS.customise && res && res.status === 'success' && layout.length !== Object.keys(app.OPTIONS.gridstack).length) {
                    this.gridstackFindDeletedWidget(layout);
                }

                if(isGraph) {
                    await this.rebuildGraph(gridstackId);
                }
            } finally {
                inputs.removeAttr('disabled').removeClass('disabled');
                if(isGraph) {
                    this.GRAPH_RELOADING[gridstackId] = false;
                    $parent.find('.gridstack-timer').show();
                }
                this.setLoadingState(gridstackId, false);
                this.populateAdditionalRows();

                Object.keys(Chart.instances).forEach((key) => {
                    const chart = Chart.instances[key];
                    chart.render();
                });
            }
        });

        $(window).on('resize', () => {
            Object.keys(Chart.instances).forEach((key) => {
                const chart = Chart.instances[key];
                chart.render();
            });
        });

        app.DOM.filter.find('.btn-reset').on('click', async (e) =>{
            e.preventDefault();
            var $el = $(e.currentTarget);

            if($el.hasClass('filters-reset')) {
                AjaxSync({
                    url: `${app.CACHE.URL_AJAX}dashboard_filter`,
                    method: 'POST',
                    data: { method: 'reset' }
                }, {
                    done: (_res) => window.location.reload(),
                });
                return;
            }

            // do nothing
            if( $el.hasClass('ajax') ){
                return;
            }

            // change icon state
            $el.addClass('ajax').find('i').addClass('fa-spin');

            try {
                const res = await this.updateLayout(undefined, 'delete');
                if(res.status === 'success') {
                    window.location.reload(); // TODO: We could in the future look to update the grid without the need for a reload
                } else {
                    if( res.status === 'error' && res.errors[0].key === 'login_simulation' ) {
                        alert(res.errors[0].msg);
                    }

                    // change icon state
                    $el.removeClass('ajax').find('i').removeClass('fa-spin');
                }
            } catch {}
        });

        // style fix
        app.DOM.content.find('.grid-stack-item-content').not('.grid-stack-item-content-noborder').prepend('<div class="bl"></div><div class="br"></div>');
    }

    gridstackControl(e) {
        var action = ( $(e.currentTarget).hasClass('fa-times') ) ? 'close' : 'settings',
            $parent = $(e.currentTarget).closest('.grid-stack-item');

        if( action === 'close' ) {
            // remove from gridstack
            this.GRID.removeWidget($parent[0]);
            // trigger change
            this.GRID._triggerEvent('change');
        }
    }

    gridstackFindDeletedWidget(layout) {
        let origL = Object.keys(app.OPTIONS.gridstack),
            newL = [];

        // create array of new layout
        $.each(layout, (k,v) => {
            newL.push(v.id);
        });

        // id of missing widget
        let id;

        // loop through original to check if there still in
        $.each(origL, (k, v) => {
            if(newL.indexOf(v) < 0) {
                id = v;

                // remove from OPTIONS
                delete app.OPTIONS.gridstack[v];
            }
        });

        // uncheck dashboard settings
        if(id) {
            app.DOM.modal_settings_input.filter(`#gridstack_allowed_${id}`).prop('checked', false);
        }
    }

    gridstackMicroModal() {
        // OPEN
        app.DOM.filter.find('#btn-settings').on('click', () => {
            MicroModal.show('modal-settings');
        });

        // OPEN
        app.DOM.gridstack.find('.modal-settings').on('click', () => {
            MicroModal.show('modal-settings');
        });

        // SAVE
        app.DOM.modal_settings.find('.modal__btn-primary').on('click', async () => {
            // save options
            let settings = {};

            // loop through allowed
            app.DOM.modal_settings_input.each((k, v) => {
                // snapshot key
                const id = v.id.replace('gridstack_allowed_','');
                settings[id] = {
                    checked: ( $(v).is(':checked') ) ? 1 : 0,
                };

                // options container
                if(app.OPTIONS.gridstack_allowed[id] && app.OPTIONS.gridstack_allowed[id].widget_options) {
                    settings[id].opts = [];
                    settings[id].opts_settings = {};
                }
            });

            // loop through settings which are checked
            app.DOM.modal_settings_input_opts.filter(':checked').each((k, v) => {

                const id = $(v).attr('data'),
                    val = v.id.replace('gridstack_allowed_opts_','');

                // add to array
                if(settings[id].checked === 1) {
                    settings[id].opts.push(val);

                    // check for any date settings
                    $(v).closest('.widget-option').find('.date-settings input').each((k, el) => {
                        const idSetting = $(el).attr('data');
                        settings[id].opts_settings[val] = {};
                        settings[id].opts_settings[val][idSetting] = $(el).val();
                    });
                }
            });

            try {
                const res = await this.updateLayout(undefined, 'settings', settings);
                if(res && res.status === 'success') {
                    // reload dashboard
                    window.location.reload();
                } else if(res && res.status === 'error') {
                    // alert re: simulated login
                    if(res.errors[0].key === 'login_simulation') {
                        alert(res.errors[0].msg);
                    }
                }
            } catch(err) { console.warn(err); }

            app.DOM.header.css('z-index', 100);
        });
    }

    clickChart(indexDataset, index, data) {
        if( data.datasets.length === 1 && data.click[index] ) {
            // single dataset
            window.location = data.click[index];
        } else if( data.datasets.length > 1 &&  data.click[indexDataset] ) {
            // multiple datasets
            window.location = data.click[indexDataset][index];
        }
    }

    setupTabs() {
        // loop through each sets of tabs
        app.DOM.content.find('.tabs').each((k, el) => {

            const $tab = $(el),
                $tabs = $tab.find('.tab'),
                $btns = $tab.find('h3 a:not(.not-tab)');

            // get index of which button to click
            let index = ( $tab.attr('gs-tab') ) ? $tab.attr('gs-tab') : 0;

            // click event for button
            $btns.on('click clickInit', async (e) => {

                // prevent reclicking
                if( $(e.currentTarget).hasClass('button-primary') ){
                    return;
                }

                // btn index used for tab index
                let index = $(e.currentTarget).index();
                if($(e.currentTarget).hasClass('tab-btn')) {
                    index = $(e.currentTarget).attr('data');
                    $tabs.removeClass('open').filter((k, el) => $(el).attr('id') === index).addClass('open');
                } else {
                    // select tab
                    $tabs.removeClass('open').eq(index).addClass('open');
                }

                // select btn
                $btns.removeClass('button-primary').addClass('button-grey');
                $(e.currentTarget).addClass('button-primary').removeClass('button-grey');

                if(this.GRAPH_CLASSES[$tab.attr('gs-id')] && this.GRAPH_CLASSES[$tab.attr('gs-id')].tabChange) {
                    await this.GRAPH_CLASSES[$tab.attr('gs-id')].tabChange(index);
                }

                if(this.TPL_CLASSES[$tab.attr('gs-id')] && this.TPL_CLASSES[$tab.attr('gs-id')].tabChange) {
                    await this.TPL_CLASSES[$tab.attr('gs-id')].tabChange(index);
                }

                // re-save
                if(e.type === 'click' && this.GRID) {
                    this.GRID._triggerEvent('change');
                    const el = $tab.find('.grid-stack-item-content');
                    el.get(0).scrollTop = 0;
                }
            });
            const selectedTab = $tab.find(`h3 a[data="${index}"]`);
            if(index === 0) {
                $($btns.get(0)).trigger('click');
                return;
            }

            if(selectedTab && selectedTab.length > 0) {
                $tab.find(`h3 a[data="${index}"]`).trigger('clickInit');
                return;
            }

            $($btns.get(0)).trigger('click');
        });
    }

    getDataGridstack() {
        let data = {};

        $.each(app.OPTIONS.gridstack, (k, v) => {
            data[k] = {
                checked: 1,
            };

            if(v.opts) {
                data[k].opts = v.opts;
            }
        });

        return data;
    }

    num(n, currency) {
        if( typeof Intl === 'undefined' ) {
            return n;
        }

        let numberPrefix = '';

        // remove any existing formatting string
        if( typeof n === 'string' ) {
            n = n.replace(',','');
        }

        let value = new Intl.NumberFormat().format(n);

        if( currency ){
            numberPrefix = '£';
            const valueEnd = value.split('.').pop();

            if( valueEnd.length === 1 ) {
                value += '0';
            }
        }

        return numberPrefix + value;
    }

    partitionData(data) {
        let tableData = {},
            graphData = {};

        for (const k in data) {
            if (app.DOM.content.find(`div#${k}`).hasClass('graphs')) {
                graphData[k] = data[k];
            } else {
                tableData[k] = data[k];
            }
        }

        return [tableData, graphData];
    }

    topupRows(minimumRowCount, $tables, rowHeight) {
        $.each($tables, (tableIndex, $table) => {
            if(!app.OPTIONS.gridstack_allowed[tableIndex] || !app.OPTIONS.gridstack_allowed[tableIndex].topup_rows || !$table || $table.length === 0) {
                return;
            }

            $table = $($table);
            if ($table[0].tagName.toLowerCase() === 'tbody') {
                $table = $table.parents('table')
            }
            const $currentRows = $table.find('tbody>tr')
            const currentRowCount = $currentRows.length;
            if (currentRowCount < minimumRowCount) {
                const cellCount = $table.find('th').filter((k, el) => $(el).css('display') !== 'none').length;
                const cells = [...Array(cellCount).keys()].fill('<td>&nbsp;</td>').join('');
                let $row = $('<tr class="topup-row">').html(cells);
                if(rowHeight) {
                    $row = $(`<tr class="topup-row" style="height:${rowHeight}px">`).html(cells);
                }

                for (let i = currentRowCount; i < minimumRowCount; ++i) {
                    $table.append($row.clone());
                }
            }
        });
    }

    setupKeys() {
        app.DOM.content.find('.btn-key').on('click', (e) => {
            const modal = $(e.currentTarget).attr('data');
            MicroModal.show(modal);
        });
    }

    setupUserVehicleHover() {
        const $hoverPreview = app.DOM.content.find('span.hover-preview[data-id]');
        $hoverPreview.on('click', e =>{

            const tbl = $(e.currentTarget).attr('data-tbl');
            const id = $(e.currentTarget).attr('data-id');

            const widgetId = $(e.currentTarget).closest('.grid-stack-item-content-inner').attr('id');
            const widget = ( widgetId ) ? `/widget:${widgetId}` : '';


            // redirect to user/driver page
            window.location = `${app.CACHE.URL_ADMIN}tacho/redirect/${tbl}/${id}${widget}`;
        });

        // convert vehicle links to vehicle reg styling
        $hoverPreview.filter('[data-tbl="veh"]').addClass('id_reg uk id_reg_uk cursor').removeClass('hover-preview');

        // convert drivers to capitalised names...
        $hoverPreview.filter('[data-tbl="usr"]').each((k, el) =>{
            let names = $(el).text().toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
            $(el).text(names);
        });
    }

    format_duration(durationInSeconds) {
        const duration = moment.duration(durationInSeconds * 1000);
        const seconds = duration.seconds(),
            minutes = duration.minutes(),
            hours = duration.hours(),
            days = duration.days();

        let returnString = [];
        if (days > 0) {
            returnString.push(`${days}d`);
        }
        if (hours > 0) {
            returnString.push(`${hours}h`);
        }
        if (minutes > 0) {
            returnString.push(`${minutes}m`);
        }
        if (seconds > 0) {
            returnString.push(`${seconds}s`);
        }

        return returnString.join(' ');
    }

    getFilters() {
        let data = {};
        $.each(app.DOM.filter.find(':input'), (_k, el) => {
            const value = $(el).val();
            if(!$(el).attr('name')) {
                return;
            }

            const field = $(el).attr('name').replace('[]','');

            if(!value || value.length === 0) {
                return;
            }

            data[field] = $.isArray(value) ? value.join(',') : value;
        });

        return data;
    }

    setupFiltersHome() {
        let fieldsWithMultiselect = [];

        app.DOM.filter.find('.multiselect-container :input.multiple').each((k, el) => {
            fieldsWithMultiselect.push($(el).attr('name').replace('[]',''));
        });

        app.DOM.filter.find(':input').not('.autocomplete').on('change', (e) => {
            let value = $(e.currentTarget).val();
            const field = $(e.currentTarget).attr('name').replace('[]','');
            // do nothing (only vehicle_id)
            if( name === 'vehicle_name' ) {
                return;
            }

            if(fieldsWithMultiselect.includes(field)) {
                app.DOM.filter.find('.multiselect-container .multiselect-label').each((k, el) => {
                    el = $(el);
                    if(el.attr('for') === field) {
                        el.find('.apply-filter').remove();
                        el.html(el.html() + `<a class="apply-filter">[Apply]</a>`);
                        el.unbind('click').on('click', (e) => {
                            e.preventDefault();
                            AjaxSync({
                                url: `${app.CACHE.URL_AJAX}dashboard_filter`,
                                method: 'POST',
                                data: this.getFilters(),
                            }, {
                                done: (_res) => window.location.reload(),
                            });
                        });
                    }
                });
                return;
            }

            AjaxSync({
                url: `${app.CACHE.URL_AJAX}dashboard_filter`,
                method: 'POST',
                data: this.getFilters(),
            }, {
                done: (_res) => window.location.reload(),
            });
        });
    }

    dateFilterUpdate(date) {
        AjaxSync({
            url: `${app.CACHE.URL_AJAX}dashboard_filter`,
            method: 'POST',
            data: {
                ...this.getFilters(),
                date_from: date.start.$input.val(),
                date_to: date.end.$input.val(),
            }
        }, {
            done: (_res) => window.location.reload(),
        });
    }

    deleteNewFeatures($el, r){
        $el.html(r);
        $el.find('a.btn-delete-new-fea').on('click', (e) => {
            e.preventDefault();
            if(confirm('Are you sure you want to delete this feature?')) {
                window.location = app.CACHE.URL_ADMIN + 'new_fea_delete/'+$(e.currentTarget).attr('data');
            }
        });
    }

    populateAdditionalRows() {
        $.each(app.DOM.content.find('.grid-stack-item'), (gridName, el) => {
            el = $(el);
            const table = el.find('table');
            if(!table || table.length === 0) {
                return;
            }

            $.each(table, (k, tableEl) => {
                tableEl = $(tableEl);
                tableEl.find('.topup-row').remove();
                const contentWrapper = el.find('.grid-stack-item-content');
                const innerWrapper = contentWrapper.find('.grid-stack-item-content-inner');
                let trueHeight = contentWrapper.height() - 28;
                if(!contentWrapper || contentWrapper.length === 0) {
                    return;
                }

                const rowHeight = $(tableEl.find('tbody>tr').get(0)).height() ?? tableEl.find('thead').height();
                const rowCount = Math.round(trueHeight / rowHeight);
                if(rowCount > 500) {
                    return;
                }

                let $tables = {};
                $tables[el.attr('gs-id')] = tableEl;
                this.topupRows(rowCount, $tables, rowHeight);
                const topupRows = tableEl.find('.topup-row');
                if(el.attr('gs-id') === 'assetgo_overview' || el.attr('gs-id') === 'er') {
                    return;
                }

                if(topupRows.length > 1) {
                    innerWrapper.removeClass('allow-overflow');
                } else {
                    innerWrapper.addClass('allow-overflow');
                }
            });
        });
    }

    getCountdownTime(expiry) {
        if(!expiry) {
            return 0; // No Cache
        }

        switch(expiry.length) {
            case 10: return (moment(expiry).add(1, 'd').diff(moment())/1000) + 60; // 1 Day + 1 Min Offset
            case 13: return (moment(expiry + ':00').add(1, 'h').diff(moment())/1000) + 60; // 1 Hour + 1 Min Offset
            case 15: return (moment(expiry + '0').add(10, 'm').diff(moment())/1000) + 60; // 10 Minutes + 1 Min Offset
            default: return 0; // No Cache
        }
    }

    msToTime(duration) {
        let seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds;
    }

    setupMessageHome() {
        var $msg = app.DOM.content.find('#message,.message'),
            $btn = $msg.find('#message-close,i.message-close');

        $btn.on('click', (e) => {

            // change message close state
            this.messageClose = true;

            // loading
            $(e.currentTarget).attr('class', 'fad fa-spin fa-spinner-third');

            AjaxSync({
                method: 'post',
                url: app.CACHE.URL_ADMIN + 'home_msg',
                data: {id: $(e.currentTarget).attr('data-message-id')}
            },
            {
                done: (res) => {
                    if(res === '1') {
                        $(e.currentTarget).closest('#message,.message').hide();
                        return;
                    }

                    console.warn(res);
                }
            });
        });
    }

    async getTachoOverview() {
        if(!app.DASHBOARD_CORE) {
            throw new Error('app.DASHBOARD_CORE not initialized unable to get overview data');
        }

        const res = await AjaxPromise({
            url: `${app.CACHE.URL_AJAX}tacho_dashboard`,
            method: 'POST',
            data: {
                allowed: app.DASHBOARD_CORE.getDataGridstack()
            }
        });

        if(res.status !== 'success') {
            throw res;
        }

        return res.data;
    }
}

$(() => {
    if(app.OPTIONS.er || app.DOM.earned) {
        new EarnedRecognition();
    }

    if(app.OPTIONS.dashboard === 'drivercheck') {
        new DrivercheckCore();
    }

    new DashboardCore();
});